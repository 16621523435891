<template>
  <div class="newsList pc-wrap" ref="container">
    <div class="pc-content">
      <div class="article">
        <BreadCrumbs />

        <div class="slider-banner swiperNews" v-if="imgUrls.length > 0">
          <swiper class="swiper-wrapper" :options="swiperNew">
            <swiperSlide
              class="swiper-slide"
              v-for="(item, index) in imgUrls"
              :key="index"
            >
              <img :src="item.image_input[0]" class="slide-image" />
            </swiperSlide>
          </swiper>
          <div class="swiper-pagination"></div>
        </div>
        <Tabs
          ref="nav"
          class="cates"
          @click="onClick"
          color="#2770d5"
          v-model="active"
          animated
          :ellipsis="false"
        >
          <Tab
            v-for="(item, index) in navLsit"
            :key="index"
            :title="item.title"
            title-inactive-color="#999"
            title-active-color="#282828"
          >
            <List
              :loading="loading"
              :finished="loadend"
              :finished-text="articleList.length ? '没有更多了' : ''"
              @load="getArticleLists"
            >
              <div
                class="list"
                v-for="(item, index) in articleList"
                :key="index"
              >
                <router-link
                  :to="{ path: '/news/' + item.id }"
                  class="item fmix-align-sb"
                  v-if="item.image_input.length === 1"
                >
                  <div class="text">
                    <div class="name line2">{{ item.title }}</div>
                    <div>{{ item.add_time }}</div>
                  </div>
                  <div class="pictrue"><img :src="item.image_input[0]" /></div>
                </router-link>
                <router-link
                  :to="{ path: '/news/' + item.id }"
                  class="item"
                  v-if="item.image_input.length === 2"
                >
                  <div class="title line1">
                    {{ item.title }}
                  </div>
                  <div class="picList acea-row row-between-wrapper">
                    <div
                      class="pictrue"
                      v-for="(itemImg, index) in item.image_input"
                      :key="index"
                    >
                      <img :src="itemImg" />
                    </div>
                  </div>
                  <div class="time">{{ item.add_time }}</div>
                </router-link>
                <router-link
                  :to="{ path: '/news_detail/' + item.id }"
                  class="item"
                  v-if="item.image_input.length === 3"
                >
                  <div class="title line1">
                    {{ item.title }}
                  </div>
                  <div class="picList on acea-row row-between-wrapper">
                    <div
                      class="pictrue"
                      v-for="(itemImg, index) in item.image_input"
                      :key="index"
                    >
                      <img :src="itemImg" />
                    </div>
                  </div>
                  <div class="time">{{ item.add_time }}</div>
                </router-link>
              </div>
            </List>
            <Empty v-show="!articleList.length && loadend" description="暂无相关新闻" />
          </Tab>
        </Tabs>
      </div>

      <AsideNewsCate before @tap-cate="onTapCate" />
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import { Tab, Tabs, List, Empty } from "vant";
import AsideNewsCate from "@/components/aside/newsCate";
import request from "@/utils/request";
const name = "NewsList";

export default {
  name,
  components: {
    swiper,
    swiperSlide,
    Tab,
    Tabs,
    List,
    Empty,
    AsideNewsCate,
  },
  data() {
    return {
      page: 1,
      limit: 20,
      loading: false,
      loadend: false,
      imgUrls: [],
      navLsit: [],
      articleList: [],
      active: 0,
      cid: 0,
      swiperNew: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 2000,
        },
        loop: true,
        speed: 1000,
        observer: true,
        observeParents: true,
      },
      pid: "",
    };
  },
  mounted() {
    const { pid, cid } = this.$route.query;
    this.pid = pid;
    this.cid = cid;

    this.articleBanner();
    this.articleCategory();
  },
  methods: {
    articleBanner() {
      let that = this;
      request.get("article/banner/list").then((res) => {
        that.imgUrls = res.data;
      });
    },
    articleCategory() {
      request.get("article/category/list?pid=" + this.pid).then((res) => {
        this.navLsit = res.data;

        this.active =
          this.navLsit.findIndex((item) => item.id == this.cid) || 0;
      });
    },
    articleHotList() {
      this.loading = true;
      request.get("article/hot/list?pid=" + this.pid).then((res) => {
        this.articleList = res.data;
        this.loading = false;
        this.loadend = true;
      });
    },
    getArticleLists(reload) {
      if (reload) {
        this.articleList = [];
        this.page = 1;
        this.loadend = false;
        this.loading = false;
      }
      let that = this;
      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      if (!this.cid) {
        return this.articleHotList();
      }
      that.loading = true;
      let q = {
        page: that.page,
        limit: that.limit,
      };
      request.get("article/list/" + this.cid, q).then((res) => {
        that.loading = false;
        that.articleList.push.apply(that.articleList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
      });
    },
    onClick(name) {
      this.cid = this.navLsit[name].id;
      this.getArticleLists(1);
    },
    onTapCate(e) {
      this.cid = e.item.id;
      this.active = e.index;
      this.getArticleLists(1);
    },
  },
};
</script>

<style scoped>
.newsList .list .item {
  margin: 0 15px;
  border-bottom: 1px solid #f0f0f0;
  padding: 18px 0;
}
.newsList .list .item .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 210px;
  height: 78px;
  font-size: 12px;
  color: #999;
}
.newsList .list .item .text .name {
  font-size: 15px;
  color: #282828;
}
.newsList .list .item .pictrue {
  width: 125px;
  height: 78px;
}
.newsList .list .item .pictrue img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
</style>
<style lang="scss">
.cates.van-tabs--line .van-tabs__wrap {
  height: 0;
}

/* 超小屏幕（手机，小于 768px） */
@media (min-width: 320px) and (max-width: 430px) {
  .cates.van-tabs--line .van-tabs__wrap {
    height: 44px;
  }
}
</style>