<template>
  <div class="aside phone-hide">
    <div class="box">
      <div class="box-title">新闻分类</div>
      <div class="box-content">
        <div
          v-for="(item, index) in cates"
          :key="index"
          class="cate-item"
          @click="toCate(item, index)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "AsideNewsCate",
  props: {
    before: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cates: [],
    };
  },
  created() {
    this.articleCategory();
  },
  methods: {
    articleCategory: function () {
      request.get("article/category/list?pid=0").then((res) => {
        this.cates = res.data;
      });
    },
    toCate(item, index) {
      if (this.before) {
        return this.$emit("tap-cate", {item, index});
      }
      this.$jump("/news/list?cid=" + item.id);
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  margin-top: 20px;
  box-shadow: $hover-shadow;

  .box-title {
    font-size: 14px;
    padding: 15px 0;
    margin: 0 20px;
    border-bottom: 1px solid $gray-c;
  }

  .box-content {
    padding: 10px 0;
  }

  .cate-item {
    padding: 10px 20px;
    color: $link;
  }
}
</style>